.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 40px;
  padding-inline-end: 8px;
  border-bottom: solid 1px #eee;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
}
