.app {
  position: relative;
  height: 100%;
}

.treeRoot {
  height: 100%;
}

.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}

.dropTarget {
  background-color: #e8f0fe;
}
