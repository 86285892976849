div.rs-picker-daterange > div.rs-picker-toggle {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 0;
}

a:active,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
